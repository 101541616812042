import { Injectable } from '@angular/core';
import { PracticeCity } from '../models/practice-city';
import { BackendService } from 'src/app/services/common/backend.service';

@Injectable(
  {providedIn:'root'}
)
export class PracticeCityService {
  private readonly practiceCitiesApiFragment:string = "/api/public/practice_cities";

  constructor(
    private backendService:BackendService
  ) { }

  /**
   * Gets the available city names from the server.
   * 
   * @returns the list of the available city names
   */
  public async fetchPracticeCities():Promise<ReadonlyArray<PracticeCity>> {
    let result:Array<PracticeCity> = [];

    try {
      const response:{ cities:Array<PracticeCity> } = await this.backendService.callApi(this.practiceCitiesApiFragment, "GET");
      result = response.cities;
    } catch(error:any) {
      throw error;
    }

    return result;
  }
}