import { enableProdMode, Injectable } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  console.log("Production 🔥");
  enableProdMode();
}else{
  console.log("Non-production 🌱");
  console.log(environment.serverAddress);
}


function bootstrap() {
  setTimeout(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
}


// Check DOMContentLoaded already happened
if (document.readyState === 'complete') {
  bootstrap();
}else{
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap();
  });
}

